<template>
  <div
    v-if="authUser && currentUserNeedsTabBar && currentRouteNeedsTabBar"
    class="bar bg-white d-flex justify-content-center border-top"
  >
    <router-link
      v-for="(item, itemIndex) in items"
      :key="`key-${itemIndex}`"
      :to="item.slugs[0]"
      :class="{
        active: item.slugs.findIndex((slug) => slug === currentRoute) > -1,
      }"
      class="flex-fill py-3 px-2 text-center d-flex flex-column align-items-center cta"
      role="button"
    >
      <fa-icon :icon="['fas', item.icon]" class="fs-5"></fa-icon>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TabBar',
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsCoach', 'authUserIsAthlete']),
    ...mapState('config', ['configUIMode']),
    currentRoute() {
      return `/${this.$route.name}`;
    },
    currentUserNeedsTabBar() {
      if (this.authUserIsAthlete) return true;
      if (this.authUserIsCoach && this.configUIMode === 'athlete') return true;
      return false;
    },
    currentRouteNeedsTabBar() {
      const match = this.items.find((item) => {
        const { slugs } = item;
        return slugs.findIndex((slug) => slug === this.currentRoute) > -1;
      });
      if (match) return true;
      return false;
    },
  },
  data() {
    return {
      items: [
        {
          slugs: ['/', '/home'],
          icon: 'calendar-week',
        },
        {
          slugs: ['/nutrition'],
          icon: 'utensils',
        },
        {
          slugs: ['/settings', '/account', '/metric'],
          icon: 'bars',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.bar {
  position: fixed;
  z-index: 700;
  left: 0;
  bottom: 0;
  right: 0;
}

.cta {
  color: $tertiaryLabel;
  transition: all .5s ease-in-out;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 0;
    height: .25rem;
    height: 2px;
    border-radius: 0;
    background-color: $primary;
    pointer-events: none;
    transition: all .5s ease-in-out;
    opacity: 0;
  }

  &.active {
    color: $primary;

    &:before {
      opacity: 1;
    }
  }
}
</style>
